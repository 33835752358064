<template>
  <div class="w-100">
    <b-form @submit.prevent="find" data-vv-scope="formFind">
      <div class="title-actions">
        <h5 class="title">{{$t('updatedData')}}</h5>
        <div class="btns">
          <b-button @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>
          </b-button>
        </div>
      </div>
      <hr>
      <div class="form-group">
        <label for="idOrUsername">{{$t('enterIdOrUsername')}}</label>
        <b-form-input v-validate="'required|alpha_num'" name="idOrUsername" id="idOrUsername" v-model="idOrUsername" :state="validateState('idOrUsername')" />
        <span class="error-inputs">{{ errors.first('idOrUsername') }}</span>
      </div>
      <b-button type="submit" variant="primary" :disabled="loading">{{$t('save')}}</b-button>
      <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
    </b-form>
    <b-modal ref="modalUpateData" :title="username" noCloseOnBackdrop noCloseOnEsc>
      <b-form @submit.prevent="save" data-vv-scope="formData" class="row">
        <div class="form-group col-12 col-sm-6">
          <label for="username">{{$t('username')}}</label>
          <b-form-input name="username" id="username" v-model="user.username" v-validate="'required|max:30'" :state="validateState('username')" />
          <span class="error-inputs">{{ errors.first('username') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="country">{{$t('country')}}</label>
          <multiselect v-validate="'required'" :state="validateState('country')" name="country" id="country" v-model="user.country" :options="countries" deselectLabel="" track-by="name" label="name" :placeholder="$t('selectOption')" :searchable="true" :allowEmpty="false" selectLabel="" selectedLabel="">
            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
          </multiselect>
          <span class="error-inputs">{{ errors.first('country') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="position">{{$t('position')}}</label>
          <multiselect v-validate="'required'" :state="validateState('position')" name="position" id="position" v-model="user.position" :options="availablePositions" deselectLabel="" track-by="value" label="text" :placeholder="$t('selectOption')" :allow-empty="false" selectLabel="" selectedLabel="">
            <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
          </multiselect>
          <span class="error-inputs">{{ errors.first('position') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="chem_style">Chem style</label>
          <multiselect v-validate="'required'" :state="validateState('chem_style')" name="chem_style" id="chem_style" v-model="user.chem_style" :options="chemStyles" deselectLabel="" :placeholder="$t('selectOption')" selectLabel="" selectedLabel="" trackBy="value" label="text" data-vv-as="chem style">
            <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
          </multiselect>
          <span class="error-inputs">{{ errors.first('chem_style') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="console">{{$t('console')}}</label>
          <multiselect v-validate="'required'" :state="validateState('console')" name="console" id="console" v-model="user.console" :options="consoles" deselectLabel="" :placeholder="$t('selectOption')" :searchable="false" selectLabel="" selectedLabel="" @select="changeNick">
          </multiselect>
          <span class="error-inputs">{{ errors.first('console') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="psnId">{{nick}}</label>
          <b-form-input name="psnId" id="psnId" v-model="user.nick" :state="validateState('psnId')" data-vv-as="nick" />
          <span class="error-inputs">{{ errors.first('psnId') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="password">{{$t('password')}}</label>
          <b-form-input name="password" id="password" type="password" v-validate="'min:6'" v-model="user.password" :state="validateState('password')" />
          <span class="error-inputs">{{ errors.first('password') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="motto">{{$t('motto')}}</label>
          <b-form-input name="motto" id="motto" v-validate="'required|max:50'" v-model="user.motto" :state="validateState('motto')" />
          <span class="error-inputs">{{ errors.first('motto') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="overall">Overall</label>
          <b-form-input type="number" name="overall" id="overall" v-validate="'required|min_value:79|max_value:99'" v-model="user.overall" :state="validateState('overall')" data-vv-as="overall" />
          <span class="error-inputs">{{ errors.first('overall') }}</span>
        </div>
        <div class="form-group col-12 col-sm-6">
          <label for="email">{{$t('email')}}</label>
          <b-form-input name="email" type="email" id="email" v-validate="'required|email'" v-model="user.email" :state="validateState('email')" />
          <span class="error-inputs">{{ errors.first('email') }}</span>
        </div>
      </b-form>
      <div slot="modal-footer">
        <b-button @click="$refs.modalUpateData.hide()">{{$t('cancel')}}</b-button>
        <b-button variant="primary" @click="save" class="ml-3">{{$t('save')}}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      idOrUsername: '',
      user: {},
      availablePositions: [],
      consoles: [],
      chemStyles: [],
      nick: '',
      username: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'countries'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('updatedData'))
    this.availablePositions = window.positions
    this.consoles = window.consoles
    this.chemStyles = window.chemStyles
    if (this.countries.length < 1) {
      this.fetchCountries()
    }
  },
  methods: {
    changeNick (val) {
      this.setNick(val)
    },
    setNick (console) {
      if (console === 'PSN') {
        this.nick = 'Psn id'
      }
      if (console === 'XBOX') {
        this.nick = 'Gamer tag'
      }
      if (console === 'PC') {
        this.nick = 'Origin'
      }
    },
    fetchCountries () {
      this.$axios.get('countries').then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_COUNTRIES', data)
      })
    },
    save () {
      this.$validator.validateAll('formData').then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.$route.params.console}/super/update-data/update/${this.user.id}`
          let payload = {
            username: this.user.username,
            nick: this.user.nick,
            motto: this.user.motto,
            email: this.user.email,
            position: this.user.position.value,
            country_id: this.user.country.id,
            console: this.user.console,
            overall: this.user.overall,
            chem_style: this.user.chem_style.value,
          }
          if (this.user.password) {
            payload.password = this.user.password
          }
          this.$axios.post(path, payload).then(() => {
            this.user = {}
            this.idOrUsername = ''
            this.$refs.modalUpateData.hide()
            this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    find () {
      this.$validator.validateAll('formFind').then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.$route.params.console}/super/update-data/find/${this.idOrUsername}`
          this.$axios.get(path).then((response) => {
            const data = response.data.data
            this.user = data
            this.username = data.username
            this.user.position = window.positions.filter((position) => {
              return position.value === data.position
            }).reduce((position) => {
              return position
            })
            this.user.chem_style = window.chemStyles.filter((chem) => {
              return chem.value === this.user.chem_style
            }).reduce((chem) => {
              return chem
            })
            this.user.country = this.countries.filter((country) => {
              return country.id === this.user.country_id
            }).reduce((country) => {
              return country
            })
            this.setNick(this.user.console)
            this.$refs.modalUpateData.show()
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
